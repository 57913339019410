<template>
  <v-app class="d-flex justify-center">
    <!-- style="background-image: linear-gradient(90deg, #00d4ff 0%, #090979 35%, #020024 100%);" -->
    <v-app-bar style="background-image: linear-gradient(90deg, #881919 0%, #d95a37 70%, #d95a37 100%);" app>
      <v-container class="d-flex align-center" style="max-width: 1280px;" fluid>
        <div class="mr-2">
          <v-img src="../assets/logo.png" max-height="32" max-width="32" />
        </div>
        <!-- <h1 class="white--text">TheBible.Media</h1> -->
        <v-toolbar-title>
          <h2 class="white--text">TheBible.Media</h2>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-col class="pa-0" cols="auto" style="height: 48px;">
          <v-select
            class="pa-0"
            v-model="$i18n.locale"
            :items="$i18n.availableLocales"
            :menu-props="{ maxHeight: '400', offsetY: true }"
            prepend-inner-icon=""
            background-color="transparent"
            @change="langChange"
            dark
            flat
            solo
          >
            <template v-slot:selection="{ item }">
              <span :class="'flag-icon flag-icon-' + $t('country', item)"></span>
              <span class="ml-2" v-if="$vuetify.breakpoint.name != 'xs'">{{ $t('name', item) }}</span>
            </template>
            <template v-slot:item="{ item }">
              <span :class="'flag-icon flag-icon-' + $t('country', item)"></span>
              <span class="ml-2">{{ $t('name', item) }}</span>
            </template>
          </v-select>
        </v-col>
      </v-container>
      <template class="px-0 mx-0" v-slot:extension v-if="$vuetify.breakpoint.name == 'xs'" style="width: 100%">
        <v-container align-with-title background-color="grey lighten-5" class="px-0">
          <v-toolbar dense style="position: sticky; top: 56px;z-index: 2;">
            <a
              onclick="return gtag_report_conversion('https://play.google.com/store/apps/details?id=com.thebible.media');"
              href="https://play.google.com/store/apps/details?id=com.thebible.media"
              title="Get it on Google Play"
              style="text-decoration:none; width: 100%"
              v-if="isAndroid"
            >
              <v-list-item two-line class="px-0">
                <v-img src="../assets/icon-app.png" max-height="32" max-width="32" class="grey lighten-2 mr-2" />
                <v-list-item-content>
                  <v-list-item-title>Holy Bible-Daily Study, Audio</v-list-item-title>
                  <v-list-item-subtitle>by TheBible.Media</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-img max-height="24" max-width="24" src="../assets/tray-arrow-down.svg" />
                </v-list-item-action>
              </v-list-item>
            </a>
            <a
              onclick="return gtag_report_conversion('https://apps.apple.com/us/app/holy-bible-daily-study-audio/id1475878915');"
              href="https://apps.apple.com/us/app/holy-bible-daily-study-audio/id1475878915"
              title="Download on the App Store"
              style="text-decoration:none;"
              v-else-if="isIOS"
            >
              <v-list-item two-line class="px-0">
                <v-img src="../assets/icon-app.png" max-height="32" max-width="32" class="grey lighten-2 mr-2" />
                <v-list-item-content>
                  <v-list-item-title>Holy Bible-Daily Study, Audio</v-list-item-title>
                  <v-list-item-subtitle>by TheBible.Media</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-img max-height="24" max-width="24" src="../assets/tray-arrow-down.svg" />
                </v-list-item-action>
              </v-list-item>
            </a>
          </v-toolbar>
        </v-container>
      </template>
    </v-app-bar>
    <v-main class="px-0 mx-0">
      <!-- <v-toolbar
        class="mx-2"
        dense
        v-if="$vuetify.breakpoint.name == 'xs'"
        style="position: sticky; top: 56px;z-index: 2;"
      >
        <v-img
          src="../assets/icon-app.png"
          max-height="32"
          max-width="32"
          class="grey lighten-2 mr-2"
        />

        <v-toolbar-title>Holy Bible-Daily Study, Audio</v-toolbar-title>

        <v-spacer></v-spacer>

        <a
          class="mr-2"
          href="http://play.google.com/store/apps/details?id=com.holy.bible.mobile.read"
          title="Get it on Google Play"
          style="text-decoration:none;"
        >
          <v-img
            max-height="24"
            max-width="24"
            src="../assets/google-play-store.svg"
          />

        </a>

        <a
          href="https://apps.apple.com/us/app/holy-bible-daily-study-audio/id1475878915"
          title="Download on the App Store"
          style="text-decoration:none;"
        >
          <v-img
            max-height="24"
            max-width="24"
            src="../assets/apple-ios-app-store.svg"
          />
        </a>

      </v-toolbar> -->
      <v-container class="d-flex justify-center" style="max-width: 1280px;" fluid>
        <v-row>
          <v-col cols="12" sm="8">
            <v-container>
              <books_list v-model="usfm" @onUsfmErr="onUsfmErr" />
              <book_content :usfm="usfm" @onUsfmErr="onUsfmErr"></book_content>
            </v-container>
          </v-col>

          <v-col cols="12" sm="4" class="d-flex justify-center">
            <v-card class="py-4" max-width="400" :style="appCardStyle">
              <v-list-item>
                <v-img src="../assets/icon-app.png" max-height="64" max-width="64" class="grey lighten-2 mr-2" />
                <v-list-item-content>
                  <v-list-item-title class="font-weight-bold">Holy Bible-Daily Study, Audio</v-list-item-title>
                  <v-list-item-subtitle>by TheBible.Media</v-list-item-subtitle>
                  <v-rating
                    v-model="rating"
                    color="yellow darken-3"
                    background-color="grey darken-1"
                    half-increments
                    dense
                    readonly
                    size="18"
                  ></v-rating>
                </v-list-item-content>
              </v-list-item>

              <v-row class="d-flex justify-center px-6 mt-2">
                <v-col v-if="!isIOS" cols="auto">
                  <a
                    onclick="return gtag_report_conversion('https://play.google.com/store/apps/details?id=com.thebible.media');"
                    href="https://play.google.com/store/apps/details?id=com.thebible.media"
                    title="Get it on Google Play"
                  >
                    <v-img src="../assets/google-play-badge.svg" />
                  </a>
                </v-col>
                <v-col v-if="!isAndroid" cols="auto">
                  <a
                    onclick="return gtag_report_conversion('https://apps.apple.com/us/app/holy-bible-daily-study-audio/id1475878915');"
                    href="https://apps.apple.com/us/app/holy-bible-daily-study-audio/id1475878915"
                    title="Download on the App Store"
                  >
                    <v-img src="../assets/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                  </a>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer app height="150" color="transparent">
      <v-container style="max-width: 1280px" fluid>
        <v-row class="d-flex align-center" style="max-width: 1280px">
          <v-col sm="8">
            <div class="d-flex align-center justify-end">
              <bible_audio v-if="showAudio" :usfm="usfm" />
              <v-btn @click="showAudio = !showAudio" color="#881919cc" elevation="4" small depressed fab>
                <v-icon color="white" v-if="showAudio">mdi-close</v-icon>
                <v-icon color="white" v-else>mdi-volume-high</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <!-- <div>&copy; {{ new Date().getFullYear() }}</div> -->
    </v-footer>
  </v-app>
</template>

<script>
import books_list from './components/BooksList'
import book_content from './components/BookContent'
import bible_audio from './components/AudioPlayer'

import { isIOS, isAndroid } from 'mobile-device-detect'

export default {
  name: 'App',

  components: {
    books_list,
    book_content,
    bible_audio,
  },

  created() {
    if (localStorage.usfm == undefined) {
      this.usfm = { ver: '1', book: 'GEN', ch: 'GEN.1' }
    }

    this.usfm = JSON.parse(localStorage.usfm)
    console.log(this.usfm)
    //this.mobileInfo()
  },

  computed: {
    appCardStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'margin-top: 20px;'
        default:
          return 'margin-top: 10px; position: fixed;'
      }
    },
  },

  data() {
    return {
      usfm: { ver: '1', book: 'GEN', ch: 'GEN.1' },
      showAudio: false,
      rating: 4.3,
      isAndroid: isAndroid,
      isIOS: isIOS,
    }
  },
  watch: {
    usfm() {
      //this.showAudio = false;
      //console.log(this.usfm);
      localStorage.usfm = JSON.stringify(this.usfm)
      console.log(localStorage.usfm)
    },
  },
  methods: {
    langChange(e) {
      console.log(e)
      localStorage.lang = e
      this.$i18n.locale = e
      this.usfm = { ver: this.$t('bibleVer'), book: 'GEN', ch: 'GEN.1' }
    },

    onUsfmErr(e) {
      console.log(e)
      this.usfm = { ver: this.$t('bibleVer'), book: 'GEN', ch: 'GEN.1' }
    },
  },
}
</script>

<style lang="scss">
.v-toolbar__extension {
  padding: 0px !important;
}

.v-application {
  font-family: Georgia, sans-serif !important;
}

.v-select.v-input--is-dirty input {
  display: none;
}

.v-input__slot.transparent {
  padding: 0px !important;
}

.v-select__selection {
  width: 100%;
  justify-content: center;
}
</style>
