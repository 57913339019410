import vuetifyIt from 'vuetify/es5/locale/it'

const it = {
  bibleVer: '122',
  name: 'Italiano',
  country: 'it',
  message: {
    text: 'Good good study, Day day up',
  },
  $vuetify: {
    ...vuetifyIt,
  },
}

export default it
