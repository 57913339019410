import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { Vue2Storage } from 'vue2-storage'
import AudioPlayer from '@liripeng/vue-audio-player'
import '@liripeng/vue-audio-player/lib/vue-audio-player.css'
import '../assets/reader.css'
import 'flag-icon-css/css/flag-icon.css'
import i18n from './i18n/i18n'

// 全局变量
Vue.prototype.$apiHost = 'https://bible.more.best/bible/'

Vue.config.productionTip = false

Vue.use(Vue2Storage, {
  prefix: 'bible_',
})
Vue.use(AudioPlayer)

new Vue({
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app')


