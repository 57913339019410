import vuetifyDe from 'vuetify/es5/locale/de'

const de = {
  bibleVer: '73',
  name: 'Deutsch',
  country: 'de',
  message: {
    text: 'Good good study, Day day up',
  },
  $vuetify: {
    ...vuetifyDe,
  },
}

export default de
