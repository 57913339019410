import zhHans from 'vuetify/es5/locale/zh-Hans'

const zh_CN = {
  bibleVer: '48',
  name: '简体中文',
  country: 'cn',
  message: {
    text: '好好学习，天天向上',
  },
  $vuetify: {
    ...zhHans,
  },
}

export default zh_CN
