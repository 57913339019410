import vuetifyFr from 'vuetify/es5/locale/fr'

const fr = {
  bibleVer: '133',
  name: 'Fran\u00e7ais',
  country: 'fr',
  message: {
    text: 'Good good study, Day day up',
  },
  $vuetify: {
    ...vuetifyFr,
  },
}

export default fr
