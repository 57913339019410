import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './langs'
Vue.use(VueI18n)

// 获取浏览器默认语言
export const getBrowserLang = function () {
  let browserLang = navigator.language ? navigator.language : navigator.browserLanguage;
  let defaultBrowserLang = '';
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    defaultBrowserLang = 'zh_CN';
  } else if (browserLang.toLowerCase().startsWith('de')) {
    defaultBrowserLang = 'de';
  } else if (browserLang.toLowerCase().startsWith('es')) {
    defaultBrowserLang = 'es';
  } else if (browserLang.toLowerCase().startsWith('fr')) {
    defaultBrowserLang = 'fr';
  } else if (browserLang.toLowerCase().startsWith('it')) {
    defaultBrowserLang = 'it';
  } else if (browserLang.toLowerCase().startsWith('zh')) {
    defaultBrowserLang = 'zh_HK';
  } else {
    defaultBrowserLang = 'en';
  }

  console.log(defaultBrowserLang);
  return defaultBrowserLang;
}

//从localStorage获取语言选择。
const i18n = new VueI18n({
  locale: localStorage.lang || getBrowserLang(), //初始未选择默认 en 英文
  messages,
})

export default i18n
