<template>
  <div
    v-if="select != null"
    data-app
  >
    <h2 class="mb-6">
      {{ select.human }} {{ curCh.human }} - {{ data.local_title }} ({{ data.abbreviation }})
    </h2>
    <v-container
      class="grey lighten-3 d-flex align-center mt-2 px-0"
      style="height: 48px;"
    >
      <v-row
        align="center"
        justify="center"
      >
        <!-- <v-col cols="0">
        <v-subheader>
        </v-subheader>
      </v-col> -->

        <v-btn
          v-on:click="onPrevBook"
          icon
        >
          <v-icon>mdi-chevron-double-left</v-icon>
        </v-btn>

        <!-- <v-col cols="3"> -->
        <v-btn
          v-on:click="onPrevChapter"
          icon
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <!-- </v-col> -->

        <v-col cols="4">
          <v-select
            v-model="select"
            :items="booksList"
            :menu-props="{ maxHeight: '400', offsetY: true }"
            item-text="human"
            item-value="usfm"
            label="Select"
            persistent-hint
            v-on:change="changeBook"
            return-object
            single-line
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            v-model="curCh"
            :items="select['chapters']"
            :menu-props="{ maxHeight: '400', offsetY: true }"
            item-text="human"
            item-value="usfm"
            label="Select"
            persistent-hint
            v-on:change="changeChapter"
            return-object
            single-line
          ></v-select>
        </v-col>
        <v-btn
          v-on:click="onNextChapter"
          icon
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-btn
          v-on:click="onNextBook"
          icon
        >
          <v-icon>mdi-chevron-double-right</v-icon>
        </v-btn>

      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "books_list",
  model: {
    prop: 'usfm',
  },
  props: {
    usfm: null,
  },
  data() {
    return {
      curVer: this.usfm.ver,
      data: null,
      errorMessage: null,
      booksList: null,
      select: null,
      curCh: null,
    };
  },
  created() {
    this.loadContent();
  },
  updated() {
    console.log(this.usfm);
  },

  methods: {
    loadContent() {
      var key = "version_" + this.usfm.ver;
      if (this.$storage.has(key)) {
        this.data = this.$storage.get(key);
        this.booksList = this.data["books"];
        //console.log(this.booksList);
        //console.log(this.usfm.book);
        this.select = this.data["books"].find(element => element.usfm == this.usfm.book);
        //console.log(this.select);
        this.curCh = this.select["chapters"].find(element => element.usfm == this.usfm.ch);
        //console.log(this.curCh);
      } else {
        fetch(this.$apiHost + "version/" + this.usfm.ver + "/books.json")
          .then(async response => {
            //console.log(response)
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }

            this.data = data;
            this.booksList = data["books"];
            console.log(this.booksList);
            this.select = data["books"].find(element => element.usfm == this.usfm.book);
            this.curCh = this.select["chapters"].find(element => element.usfm == this.usfm.ch);
            this.$storage.set(key, data);
          })
          .catch(error => {
            this.errorMessage = error;
            console.error("There was an error!", error);
          });
      }
    },
    changeBook(a, showLast = false) {
      //this.$router.push({path: a })
      //console.log(a.human);
      if (showLast) {
        this.curCh = a["chapters"][a["chapters"].length - 1];
      } else {
        this.curCh = a["chapters"][0];
      }
    },
    changeChapter(a) {
      //this.$router.push({path: a })
      console.log(a.usfm);
    },
    onPrevBook(showLast = false) {
      //console.log('chevron-double-left')
      var idx = this.booksList.indexOf(this.select);
      var len = this.booksList.length;
      //console.log((idx - 1 + len) % len);
      this.select = this.booksList[(idx - 1 + len) % len];
      this.changeBook(this.select, showLast);
      //console.log(this.select.human);
    },
    onNextBook() {
      //console.log('onNextBook')
      var idx = this.booksList.indexOf(this.select);
      var len = this.booksList.length;
      //console.log((idx + 1) % len);
      this.select = this.booksList[(idx + 1) % len];
      this.changeBook(this.select);
      //console.log(this.select.human);
    },
    onPrevChapter() {
      //console.log('onPrevChapter')
      var idx = this.select["chapters"].indexOf(this.curCh);
      if (idx == 0) {
        this.onPrevBook(true);
      } else {
        this.curCh = this.select["chapters"][(idx - 1)];
        this.changeChapter(this.curCh);
      }
    },
    onNextChapter() {
      //console.log('onNextChapter')
      var idx = this.select["chapters"].indexOf(this.curCh);
      var len = this.select["chapters"].length;
      if (idx == len - 1) {
        this.onNextBook();
      } else {
        this.curCh = this.select["chapters"][idx + 1];
        this.changeChapter(this.curCh);
      }
    }
  },
  watch: {
    curCh(val) {
      this.$emit("input", { ver: this.usfm.ver, book: this.select.usfm, ch: val.usfm });//③组件内对myResult变更后向外部发送事件通知
    },
    usfm() {
      if (this.curVer == this.usfm.ver &&
        this.curCh != null &&
        this.curCh.usfm == this.usfm.ch) {
        return;
      }

      this.curVer = this.usfm.ver;
      //this.curUsfm = val; //②监听外部对props属性result的变更，并同步到组件内的data属性myResult中
      //console.log(this.curUsfm);
      this.loadContent();
    }
  },
};
</script>
