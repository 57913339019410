import vuetifyEs from 'vuetify/es5/locale/es'

const es = {
  bibleVer: '149',
  name: 'Spanish',
  country: 'es',
  message: {
    text: 'Good good study, Day day up',
  },
  $vuetify: {
    ...vuetifyEs,
  },
}

export default es
