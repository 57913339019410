import vuetifyEn from 'vuetify/es5/locale/en'

const en = {
  bibleVer: '1',
  name: 'English',
  country: 'us',
  message: {
    text: 'Good good study, Day day up',
  },
  $vuetify: {
    ...vuetifyEn,
  },
}

export default en
