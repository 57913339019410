<template>
  <v-container>
    <audio
      ref="audio"
      :src="url"
      @timeupdate="onTimeUpdate"
      @loadedmetadata="onLoadedmetadata"
      v-on="$listeners"
      v-bind="$attrs"
      controls
      controlsList="nodownload"
    ></audio>
  </v-container>
</template>

<script>
export default {
  name: "bible_audio",
  props: {
    usfm: Object,
  },
  data() {
    return {
      duration: '', // 音频持续时间
      url: '',
      timing: [],
      nextVerse: 0,
    }
  },
  created() {
    this.loadAudio();
  },
  watch: {
    usfm() {
      //console.log(this.curUfsm);
      this.$refs.audio.pause();
      this.$refs.audio.currentTime = 0;
      this.loadAudio();
    }
  },
  methods: {
    loadAudio() {
      this.nextVerse = 0;
      this.timing = [];
      this.url = this.$apiHost + "audio/" + this.usfm.ver + "/" + this.usfm.book + "/" + this.usfm.ch + ".mp3";
      var key = "v" + this.usfm.ver + "_audio_" + this.usfm.ch;
      if (this.$storage.has(key)) {
        this.timing = this.$storage.get(key);
      }

      //console.log(this.timing);
      if (this.timing == null || this.timing.length == 0) {
        var req = this.$apiHost + "audio/" + this.usfm.ver + "/" + this.usfm.book + "/" + this.usfm.ch + "_audio_play.json";
        fetch(req).then(async response => {
          //console.log(response)
          const data = await response.json();

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response statusText
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
          }

          this.timing = data["timing"];
          console.log(this.timing);
          this.$storage.set(key, this.timing, { ttl: 3600 * 1000 });
        })
          .catch(error => {
            this.errorMessage = error;
            console.error("There was an error!", error);
          });
      }
    },
    // 当前的播放位置发送改变时触发
    onTimeUpdate() {
      //console.log(event)
      //console.log(this.$refs.audio.currentTime);
      //console.log(this.timing.length);
      if (this.$refs.audio.currentTime < this.nextVerse) {
        return;
      }

      for (var i = 0; i < this.timing.length; i++) {
        if (this.$refs.audio.currentTime > this.timing[i]["end"]) {
          continue;
        }

        if (this.$refs.audio.currentTime >= this.timing[i]["start"]) {
          this.nextVerse = this.timing[i]["end"];
          this.scrollToVerse(this.timing[i]["usfm"].split(".")[2]);
          break;
        }
      }
    },
    // 当媒介元素的持续时间以及其它媒介已加载数据时运行脚本
    onLoadedmetadata() {
      //console.log(event);
      this.duration = this.$refs.audio.duration;
      console.log(this.duration);
      //this.$emit('loadedmetadata', event);
    },

    verseScrollInto(verse) {
      if (verse == "0") {
        return;
      }

      var obj = document.getElementsByClassName("verse v" + verse)[0];
      obj.scrollIntoView();
    },

    scrollToVerse(verse) {
      if (verse == "0") {
        this.clearVerseClass("active_audio_verse");
        return;
      }

      this.clearVerseClass("active_audio_verse");
      this.addVerseClass("v" + verse, "active_audio_verse");
      var obj = document.getElementsByClassName("verse v" + verse)[0];
      var rect = obj.getBoundingClientRect();
      //onlog.postMessage(window.innerHeight + "," + rect.bottom);
      if (window.innerHeight > rect.bottom + 48) {
        return;
      }

      obj.scrollIntoView({ behavior: "smooth", block: "start" });
    },

    scrollToUsfm(usfm) {
      if (usfm == "") {
        this.clearVerseClass("active_audio_verse");
        return;
      }

      //    console.log("scrollToUsfm:" + usfm);
      this.clearVerseClass("active_audio_verse");
      var x = document.querySelectorAll('span[data-usfm="' + usfm + '"]');
      for (var i = 0; i < x.length; i++) {
        x[i].classList.add("active_audio_verse");
      }

      var obj = x[0];
      var rect = obj.getBoundingClientRect();
      //gi    conlog.postMessage(window.innerHeight + "," + rect.bottom);
      if (rect.top >= 0 && window.innerHeight > rect.bottom + 100) {
        //        console.log("scrollToUsfm:" + window.innerHeight + "," + rect.bottom);
        return;
      }

      obj.scrollIntoView({ behavior: "smooth", block: "start" });
      //    window.scrollTo(0, rect.top - 72);
    },

    clearVerseClass(cls) {
      var x = document.getElementsByClassName("verse");
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove(cls);
      }
    },

    addVerseClass(v, cl) {
      var x = document.getElementsByClassName("verse " + v);
      for (var i = 0; i < x.length; i++) {
        x[i].classList.add(cl);
      }
    },

    removeVerseClass(v, cl) {
      var x = document.getElementsByClassName("verse " + v);
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove(cl);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
audio {
  width: 100%;
  //border-color: #e91e63aa;
}

// audio::-webkit-media-controls-panel {
//   // background: #f8bbd0;
//   //background: #e1f5fe;
// }

.audio__time-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: -1px;
}

.vueAudioBetter {
  display: flex;
  align-items: center;
  //   justify-content: space-between;
  overflow: hidden;
  width: 600px;
  height: 40px;
  line-height: 40px;
  margin: 14px;
  padding: 0 14px;
  border-radius: 20px;
  box-shadow: 5px 5px 10px -4px #63645e;
  //   background-image: linear-gradient(to right, #9ca5f5, #7ff5ae);
}
.vueAudioBetter span {
  font-size: 16px;
  color: #1f0719c7;
  cursor: pointer;
}
.operate span:last-child {
  position: relative;
  top: -1px;
  font-size: 14px;
  color: #1f0719c7;
  cursor: default;
}
.vueAudioBetter .iconfont:active {
  position: relative;
  top: 2px;
  left: 2px;
}
.vueAudioBetter .slider {
  position: relative;
  top: 1px;
  width: 80%;
  height: 4px;
  background: rgb(248, 247, 247);
  border-radius: 2px;
  cursor: pointer;
}
.slider .process {
  position: absolute;
  left: 0;
  top: 1px;
  width: 112px;
  height: 4px;
  border-radius: 2px;
  background: #409eff;
}
.slider .thunk {
  position: absolute;
  left: 10px;
  top: -1px;
  width: 8px;
  height: 8px;
}
.slider .block {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1px solid #409eff;
  background: rgba(255, 255, 255, 1);
  transition: 0.2s all;
}
.slider .block:hover {
  transform: scale(1.1);
  opacity: 0.6;
}
.slider .progressInfo {
  position: absolute;
  top: -28px;
  color: #117eeb;
  font-weight: 600;
}
</style>