<template>
  <div
    v-if="data != null"
    class="reader"
    :style="readStyle"
    v-html="getContent()"
  ></div>
</template>

<script>
export default {
  name: "book_content",
  props: {
    usfm: Object
  },
  data() {
    return {
      data: null,
    };
  },
  created() {
    this.loadContent();
  },
  computed: {
    readStyle() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return "padding: 50px 10px 0px 10px;";
        default: return "padding: 50px 40px 0px 40px;";
      }
    },
  },
  methods: {
    async loadContent() {
      const key = "version_" + this.usfm.ver + "_" + this.usfm.ch;
      if (this.$storage.has(key)) {
        this.data = this.$storage.get(key);
      } else {
        fetch(this.$apiHost + "version/" + this.usfm.ver + "/" + this.usfm.book + "/" + this.usfm.ch + ".json")
          .then(async (response) => {
            //console.log(response)
            const data = await response.json();

            // check for error response
            if (!response.ok) {
              // get error message from body or default to response statusText
              const error = (data && data.message) || response.statusText;
              return Promise.reject(error);
            }

            this.data = data;
            this.$storage.set(key, data);
            //console.log(this.data);
          })
          .catch((error) => {
            this.errorMessage = error;
            this.$emit('onUsfmErr', 'loadContent err!');
            console.error("There was an error!", error);
          });
      }
    },

    getContent() {
      if (this.usfm.ver == 48) {
        var dat = this.data.content.replace(new RegExp("&#12288;&#31070;", "gm"), "&#31070;");
        dat = dat.replace(new RegExp(" &#31070;", "gm"), "&#31070;");
        //console.log(dat);
        return dat;
      }

      return this.data.content;
    }
  },
  watch: {
    usfm() {
      //this.curUsfm = val; //②监听外部对props属性result的变更，并同步到组件内的data属性myResult中
      //console.log(this.curUsfm);
      this.loadContent();
    }
  },
  updated() {
    //console.log(this.curUsfm);
  }
};
</script>
