import en from './en'
import es from './es'
import de from './de'
import it from './it'
import fr from './fr'
import zh_CN from './zh_CN'
import zh_HK from './zh_HK'

export default {
  en: en,
  es: es,
  de: de,
  it: it,
  fr: fr,
  zh_CN: zh_CN,
  zh_HK: zh_HK,
}
