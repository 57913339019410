import zhHant from 'vuetify/es5/locale/zh-Hant'

const zh_HK = {
  bibleVer: '46',
  name: '繁體中文',
  country: 'hk',
  message: {
    text: '好好学习，天天向上',
  },
  $vuetify: {
    ...zhHant,
  },
}

export default zh_HK
